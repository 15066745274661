<template>
  <div class="w-full pl-3 flex">
    <div class="w-full flex flex-col">
      <scroll-container height="450px">
      <div class="flex w-full flex-wrap">
      <div
        class="w-1/2 flex my-8"
        v-for="(birthday, index) in birthdays"
        :key="index"
      >
        <div class="flex">
          <img
            v-if="birthday.photo !== null"
            class="mr-2 rounded"
            alt="picture"
            style="width: 50px; height: 50px"
            :src="birthday.photo"
          />
          <div
            style="height: 50px; width: 50px; border-radius: 5px;"
            class="text-blueCrayola border text-center font-semibold pt-3 mr-2"
            v-else
          >
            {{ $getInitials(`${birthday.fname} ${birthday.lname}`) }}
          </div>
        </div>

        <div class="w-full flex flex-col mr-3">
          <div class="text-base font-semibold capitalize">
            {{ `${birthday.fname} ${birthday.lname}` }}
          </div>
          <div class="text-xs mt-1 font-black text-blueCrayola">
            {{ `${birthday.date}` }}
          </div>
          <!--<div class="mt-1">
            <CText
              class="w-full"
              :placeholder="`Wish ${birthday.fname} a Happy Birthday`"
              style="height: 30px"
              v-model="message"
            />
            <Icon
              icon-name="icon-send"
              class="-mt-1 xsm absolute"
              style="color: #FFFFFF; right: 0; margin-right: 6vw; margin-top: -1%"
            />
          </div>--->
        </div>
      </div>

      </div>
      </scroll-container>
    </div>
  </div>
</template>

<script>
import ScrollContainer from "@/components/ScrollContainer";

export default {
  components: {
    ScrollContainer
  },

  props: {
    length: {
      type: String,
      default: "0"
    },

    birthdays: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      password: ""
    };
  }

  /* mounted() {
        for(let i = 0; i < this.birthdays.length; i++) {
        const keyNames = Object.keys(this.birthdays[i]);
        console.log(keyNames[0]);
        }
    } */
};
</script>
